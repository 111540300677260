import React, {} from "react";
import * as htmlToImage from 'html-to-image';
import loader from "./LoaderComponent";


const ImageBuilderModal = (props) => {
	
	
	const show = props.show;
	const filename = props.filename;
	
	if(!show){
		return <></>;
	}
	
	loader.show();
	
	setTimeout(() => {
		  toUpdateImage();
	  },5);
	
	
	function makeid() {
		  var text = "";
		  var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

		  for (var i = 0; i < 5; i++)
		    text += possible.charAt(Math.floor(Math.random() * possible.length));

		  return text;
	}
	
	
    function toSaveImage () {
    	
    	loader.show();
    	var dataUrl = document.getElementById('preview_image').getAttribute("src");
    	
    	let link = document.createElement('a');
	    //link.download = makeid()+'.png';
	    link.download = filename+'.png';
	    
	    link.id = makeid();
	    
	    link.href = dataUrl;
	    link.click();
	      
	    
	    setTimeout(() => {
	    	props.close();
	    	loader.hide();
		 },100);
	    
    }
    
    function toUpdateImage(){
    	var node = document.getElementById('zoom-container');
    	
    	//#undo-redo-action, #magnify
    	
    	var undo_redo_action = document.getElementById('undo-redo-action');
    	var magnify = document.getElementById('magnify');
    	let uploaded_image = document.querySelector('.dragImage').getAttribute("src");
		var imagedraggablecontainer = document.getElementById('image-draggable-container');

		if(uploaded_image == ''){
			node.removeChild(imagedraggablecontainer);
		}


    	undo_redo_action.setAttribute("style", "display:none;");
    	magnify.setAttribute("style", "display:none;");
    	
    	
    	htmlToImage.toPng(node)
		  	  	.then(function (dataUrl) {
			  		  //console.log("Data url : ",dataUrl);
			  		  document.getElementById('preview_image').setAttribute("src", dataUrl);
			  		  
			  		undo_redo_action.setAttribute("style", "display:block;");
			    	magnify.setAttribute("style", "display:block;");
			    	node.appendChild(imagedraggablecontainer);
					
			    	setTimeout(() => {
				    	loader.hide();
					 },100);
			  		    
			  	}).catch(function (error) {
			  		console.error('oops, something went wrong!', error);
			  	});
    	
    }
    
   
    
    
	return (
			  <>
			  <div className="modal show" id="savemodal" aria-modal="true" role="dialog" style={{display: "block", "paddingLeft": "0px"}}>

			  	<div className="modal-dialog modal-lg  modal-dialog-centered">
			  		<div className="modal-content custom-modal">
			  			<div className="modal-body designerstyle">
							  <div id="saveImg">
							  	<img id="preview_image" src="" alt=""/>
							  </div>
				
							  <div className="action-button">
								<button className="btn btn-ok" onClick={toSaveImage}>Download</button> 
								<button className="btn btn-cancel" onClick={props.close}>Cancel</button>
							</div>
					    </div>
			  		</div>	
			  	</div>
			  </div>
			 
			<div className="modal-backdrop show"></div>
			</>
		
	  );
    
}



export default ImageBuilderModal;
