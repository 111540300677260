
const appConfig = window.globalConfig;

document.title = appConfig.SITENAME;

export function setTitle(titile){
	document.title = titile;
}

export const API_URL = appConfig.API_URL;
export const BASE_URL = appConfig.BASE_URL;