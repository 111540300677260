import React,{useState,useRef, useEffect} from "react";
import {zoomOut, zoomIn, isSticky, isMobile} from "../lib/Hooks";
import { ReactSVG } from 'react-svg';
import $ from 'jquery';

import ImageDrag from "./ImageDragComponent";
import TextDrag from "./TextDragComponent";



const PUBLIC_URL = process.env.PUBLIC_URL;


function Middle(props) {
	
	const ref = useRef();
	
	//const home = props.home;
	
	const [home,setHome] = useState(props.home);
	const isResize = true;
	
	async function undo(){
		
		if(home.state.undoRedo.undo === false){
			return false;
		}
		
		let pattern = home.state.pattern;
		const pattern_id = home.state.pattern.id;
		
		let history =  await home.history.undo(pattern_id);
		
		//console.log("Get Undo : ", history);
		//console.log("Undo : ",home.history.isUndoAllowed(), "Redo : ",home.history.isRedoAllowed());
		
		home.setState({undoRedo:home.history.getUndoRedo()});
		
		
		if(history && history.type == "COLOR"){
			
			Object.entries(history.data).forEach(([key, layer]) => {
				//console.log("Iterate : ", key, layer);
				$("."+layer.key).css(layer.type,layer.color);
				pattern.layers[layer.key].color = layer.color;
			});
			//set color to state
			home.setState({pattern:pattern});
		}
		else if(history && history.type == "TEXTBOXRESIZE"){
			$("#dragText").attr('style',history.data);
		}
		else if(history && history.type == "TEXTBOXDRAG"){
			$("#text-dragable").attr('style',history.data);
		}
		else if(history && history.type == "IMAGERESIZE"){
			$("#image-dragable").attr('style',history.data);
		}
		else if(history && history.type == "IMAGEDRAG"){
			$("#image-dragable").attr('style',history.data);
		}
		
	}
	
	async function redo(){
		
		if(home.state.undoRedo.redo === false){
			return false;
		}
		
		let pattern = home.state.pattern;
		const pattern_id = home.state.pattern.id;
		
		let history =  await home.history.redo(pattern_id);
		
		//console.log("Get Redo : ", history);
		//console.log("Undo : ",home.history.isUndoAllowed(), "Redo : ",home.history.isRedoAllowed());
		home.setState({undoRedo:home.history.getUndoRedo()});
		
		if(history && history.type == "COLOR"){
		
			Object.entries(history.data).forEach(([key, layer]) => {
				//console.log("Iterate : ", key, layer);
				$("."+layer.key).css(layer.type,layer.color);
				pattern.layers[layer.key].color = layer.color;
			});
			
			//set color to state
			home.setState({pattern:pattern});
			
		}
		else if(history && history.type == "TEXTBOXRESIZE"){
			$("#dragText").attr('style',history.data);
		}
		else if(history && history.type == "TEXTBOXDRAG"){
			$("#text-dragable").attr('style',history.data);
		}
		else if(history && history.type == "IMAGERESIZE"){
			$("#image-dragable").attr('style',history.data);
		}
		else if(history && history.type == "IMAGEDRAG"){
			$("#image-dragable").attr('style',history.data);
		}
		
	}
	
	
	
	
	
	
	useEffect(() => {
		
			if(props.home !== home){
				setHome(props.home);
				//console.log("Changed home : ",home);
			}
			
		  
	}, [props,home]);
	


	useEffect(() => {
            window.addEventListener('resize', isMobile);
            isMobile();
            return () => {
                window.removeEventListener('resize', isMobile);
            };
        });
	
	useEffect(() => {
            window.addEventListener('scroll', isSticky);
            return () => {
                window.removeEventListener('scroll', isSticky);
            };
        });
	
	
  return (
		  <>
		  {(home.state.pattern && home.state.pattern.builder_image) && 
			 
		  <div id="zoom-container-outer" className="col col-md-6 col-lg-6 zoom-container-outer" >
	        <section id="desktop-builder">
	          <div className="zoom-container" id="zoom-container" style={{height:"600px"}}>
	            <div className="undo-redo-action" id="undo-redo-action">
	              <ul>
	              
	                <li onClick={(e) => {undo()}} className={(home.state.undoRedo.undo)?'':'disabled'}>
	                  <svg width="32px" height="32px" viewBox="0 0 32 32" id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg">
	                    <defs>
	                      <style dangerouslySetInnerHTML={{__html: "\n                                                    .cls-1 {\n                                                    fill: none;\n                                                    }\n                                                " }} />
	                    </defs>
	                    <path d="M20,10H7.8149l3.5874-3.5859L10,5,4,11,10,17l1.4023-1.4146L7.8179,12H20a6,6,0,0,1,0,12H12v2h8a8,8,0,0,0,0-16Z" />
	                    <rect id="_Transparent_Rectangle_" data-name="<Transparent Rectangle>" className="cls-1" width={32} height={32} />
	                  </svg>
	                  <span>UNDO</span>
	                </li>
	            
	                <li onClick={(e) => {redo()}} className={(home.state.undoRedo.redo)?'':'disabled'}>
	                  <svg width="32px" height="32px" viewBox="0 0 32 32" id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg">
	                    <defs>
	                      <style dangerouslySetInnerHTML={{__html: "\n                                                    .cls-1 {\n                                                    fill: none;\n                                                    }\n                                                " }} />
	                    </defs>
	                    <path d="M12,10H24.1851L20.5977,6.4141,22,5,28,11,22,17l-1.4023-1.4146L24.1821,12H12a6,6,0,0,0,0,12h8v2H12a8,8,0,0,1,0-16Z" transform="translate(0 0)" />
	                    <rect id="_Transparent_Rectangle_" data-name="<Transparent Rectangle>" className="cls-1" width={32} height={32} />
	                  </svg>
	                  <span>REDO</span>
	                </li>
	              
	              </ul>
	            </div>
	         	        
				<ImageDrag home={home}/>        
				<TextDrag home={home}/>	
			   
	            <ReactSVG ref={ref} id="builder-svg" className="builder-svg" src={home.state.pattern.builder_image} />
	            
	            <div className="magnify" id="magnify">
	              <ul>
	                <li onClick={zoomOut} className="zoon-out" data-value="desktop-builder"><img alt="" src={PUBLIC_URL + '/assets/images/plus-svgrepo-com.svg'}  /></li>
	                <li onClick={zoomIn} className="zoon-in" data-value="desktop-builder"><img alt=""  src={PUBLIC_URL + '/assets/images/minus-svgrepo-com.svg'} /></li>
	              </ul>
	            </div>
	          </div>
	        </section>
	      </div>
		  }
	      </>
		 
  );
}

export default Middle;




            
            
            
