import Dexie, { Table } from 'dexie';

Dexie.delete('builder.pearsox.com');

export const db = new Dexie('builder.pearsox.com');


db.version(1).stores({
  histories: '++id,[pattern_id+undo_redo],[pattern_id+current_index],is_last,is_default,type,pattern,data', // Primary key and indexed props
});


const COLOR = 'COLOR';
const IMAGE = 'IMAGE';

const TEXTBOXDRAG = 'TEXTBOXDRAG';
const TEXTBOXRESIZE = 'TEXTBOXRESIZE';
const TEXTCOLOR = 'TEXTCOLOR';



const DEFAULT_UNDO_REDO = 0;
const UNDO = -1;
const REDO = 1;


class History {

	default_layers;
	current_index = 0;
	
	is_undo_allowed = false;
	is_redo_allowed = false;
	
	isUndoAllowed(){
		return this.is_undo_allowed;
	}
	
	isRedoAllowed(){
		return this.is_redo_allowed;
	}
	
	getUndoRedo(){
		//console.log("get undo redo : ", {undo:this.is_undo_allowed,redo:this.is_redo_allowed});
		return {undo:this.is_undo_allowed,redo:this.is_redo_allowed};
	}
	
	
	async setDefaultLayers(pattern_id,layers){
		if(this.default_layers){
			return true;
		}
		try {
	      const id = await db.histories.add({pattern_id:pattern_id,undo_redo:UNDO,current_index:0,is_last:0,is_default:1,type:COLOR,data:layers});
	      this.default_layers = id;
	      
	    } catch (error) {
	    	return false;
	    }
		
		return true;
	}
	
	
	async pushLayers(pattern_id,layers){
		
		const last_result = await db.histories.where({pattern_id:pattern_id,undo_redo:DEFAULT_UNDO_REDO}).last();
		
		if(last_result){
			await db.histories.update(last_result.id, {undo_redo: UNDO,current_index:0,is_last:0});
			
			//console.log("last_result : ",last_result);
		}
		const id = await db.histories.add({pattern_id:pattern_id,undo_redo:DEFAULT_UNDO_REDO,current_index:1,is_last:1,is_default:0,type:COLOR,data:layers});
		this.is_undo_allowed = true;
	}
	
	async pushTextBox(pattern_id,type,data){
		
		const last_result = await db.histories.where({pattern_id:pattern_id,undo_redo:DEFAULT_UNDO_REDO}).last();
		
		if(last_result){
			await db.histories.update(last_result.id, {undo_redo: UNDO,current_index:0,is_last:0});
			
			//console.log("last_result : ",last_result);
		}
		const id = await db.histories.add({pattern_id:pattern_id,undo_redo:DEFAULT_UNDO_REDO,current_index:1,is_last:1,is_default:0,type:type,data:data});
		this.is_undo_allowed = true;
	}
	
	async undo(pattern_id){
		
		//Get and Update last data undo to redo
		let undo_result = await db.histories.where({pattern_id:pattern_id,undo_redo:UNDO}).last();
		if(undo_result){
			
			//console.log("Undo found ..... ", undo_result);
			
			if(undo_result.current_index == 1){
				await db.histories.update(undo_result.id, {undo_redo: REDO,current_index:0});
				undo_result = await db.histories.where({pattern_id:pattern_id,undo_redo:UNDO}).last();
			}else{
				const temp = await db.histories.where({pattern_id:pattern_id,current_index:1}).first();
				if(temp){
					await db.histories.update(temp.id, {undo_redo: REDO,current_index:0});
				}
			}
			
			await db.histories.update(undo_result.id, {undo_redo: REDO,current_index:1});
			
			
			//Start -- for only first undo of any changes
			/*const temp = await db.histories.where({pattern_id:pattern_id,undo_redo:DEFAULT_UNDO_REDO}).first();
			if(temp){
				await db.histories.update(temp.id, {undo_redo: REDO,current_index:0});
			}*/
			//End -- for only first undo of any changes
			
			
			if(undo_result.is_default == 1){
				this.is_undo_allowed = false;
				this.is_redo_allowed = true;
			}
			else if(undo_result.is_last == 1){
				this.is_undo_allowed = true;
				this.is_redo_allowed = false;
			}
			else{
				this.is_undo_allowed = true;
				this.is_redo_allowed = true;
			}
			
			return undo_result;
		}
		else{
			this.is_undo_allowed = false;
			this.is_redo_allowed = true;
		}
		
	}
	
	async redo(pattern_id){
		let redo_result = await db.histories.where({pattern_id:pattern_id,undo_redo:REDO}).first();
		//console.log("Redo result : ", last_result);
		
		if(redo_result){
			
			if(redo_result.current_index == 1){
				await db.histories.update(redo_result.id, {undo_redo: UNDO,current_index:0});
				redo_result = await db.histories.where({pattern_id:pattern_id,undo_redo:REDO}).first();
			}
			else{
				const temp = await db.histories.where({pattern_id:pattern_id,current_index:1}).first();
				if(temp){
					await db.histories.update(temp.id, {undo_redo: UNDO,current_index:0});
				}
			}
			
			await db.histories.update(redo_result.id, {undo_redo: UNDO,current_index:1});
			
			if(redo_result.is_default == 1){
				this.is_undo_allowed = false;
				this.is_redo_allowed = true;
			}
			else if(redo_result.is_last == 1){
				this.is_undo_allowed = true;
				this.is_redo_allowed = false;
			}
			else{
				this.is_undo_allowed = true;
				this.is_redo_allowed = true;
			}
			
		}
		else{
			this.is_undo_allowed = true;
			this.is_redo_allowed = false;
		}
		return redo_result;
		
	}
	
	
};


export default History;

