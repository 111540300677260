import React from "react";
import $ from 'jquery';
import 'jquery-ui';
import 'jquery-ui/ui/widgets/draggable'
import 'jquery-ui/ui/widgets/resizable'
const PUBLIC_URL = process.env.PUBLIC_URL;


export default class DragAndDrop extends React.Component{
	
	constructor(props) {
	    super(props);
	    
	    this.home = props.home;
	    
	    //console.log("home : ", this.home);
	    
	    this.state = {
		    	rotate:0
		};
	    
	    this.handleOnRotateLeft = this.handleOnRotateLeft.bind(this);	
	    this.handleOnRotateRight = this.handleOnRotateRight.bind(this);	
	    //this.src = props.src;
	}
	
	handleOnRotateLeft(event){
		this.setState({rotate:(this.state.rotate-2)});
	}
	handleOnRotateRight(event){
		this.setState({rotate:(this.state.rotate+2)});
	}
	
	componentDidMount() {
		
		$("#dragable").draggable({ handle: ".dragHandler" });
		$("#dragable").resizable({autoHide:true});
        
		$("#dragable .drag_close").click(function(){
			$(".draggable-container").hide();
		});
    }
	
	
	
	render() {
		return (
				<div className="draggable-container" style={{display:"none"}}>
				
					 <div id="dragable" className={'draggable'}>
						<button type="button" className="btn-actions">
						
						  
						  <span className="drag-rotate-left" onClick={this.handleOnRotateLeft}>
							  <svg width="20px" height="20px" viewBox="0 0 32 32" id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg">
			                    <defs>
			                      <style dangerouslySetInnerHTML={{__html: "\n.cls-1 {\nfill: none;\n}\n"}} />
			                    </defs>
			                    <path d="M20,10H7.8149l3.5874-3.5859L10,5,4,11,10,17l1.4023-1.4146L7.8179,12H20a6,6,0,0,1,0,12H12v2h8a8,8,0,0,0,0-16Z" />
			                    <rect id="_Transparent_Rectangle_" data-name="<Transparent Rectangle>" className="cls-1" width={20} height={20} />
			                  </svg>
						  </span>
						  
						  <span className="drag-rotate-right" onClick={this.handleOnRotateRight}>
								  <svg width="20px" height="20px" viewBox="0 0 32 32" id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg">
				                    <defs>
				                      <style dangerouslySetInnerHTML={{__html: "\n.cls-1 {\nfill: none;\n}\n"}} />
				                    </defs>
				                    <path d="M12,10H24.1851L20.5977,6.4141,22,5,28,11,22,17l-1.4023-1.4146L24.1821,12H12a6,6,0,0,0,0,12h8v2H12a8,8,0,0,1,0-16Z" transform="translate(0 0)" />
				                    <rect id="_Transparent_Rectangle_" data-name="<Transparent Rectangle>" className="cls-1" width={20} height={20} />
				                  </svg>
						  </span>
						  
						  <span className="drag_close">x</span>
						</button>
						<div className="dragHandler">
							{(this.home.state.isLogo === true)?
								(
										<><img style={{rotate:this.state.rotate+"deg"}} className="dragImage" src="" alt=""/></>
								):(
										<><div className="dragText" style={{color:this.home.state.builderTextColor.color,fontFamily:this.home.state.builderTextColor.fontFamily,rotate:this.state.rotate+"deg"}}>{this.home.state.builderText}</div></>
								)
							}
						</div>
					    
					    	
					  </div>
				  
				  </div>
				);
	}

}