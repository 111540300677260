import React, { useEffect, useRef, useState } from 'react';
//const PUBLIC_URL = process.env.PUBLIC_URL;


	
function Color(props){ 
 
  const ref = useRef();
  
  const {data, changeColor, index,colors} = props;

  //console.log("Colors : ",colors)



  
  //const value = data.value;
  
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [paletteStyle, setPaletteStyle] = useState({});
  
  
  //const [data,setData] = useState(data);
  
  
  const [value,setValue] = useState(data.color);
  
  if(value != data.color){
	  setValue(data.color);
  }
  
  //setData(props.data);
  //setValue(props.data.value);
  
  //console.log("Color : ",data,value,data.color); 
  
  function setColor(row) {
	 setValue(row);
	  
	  if(changeColor){
		  //console.log("Change event : ",row);
		 changeColor(row,index);
	  }
	  
  }
  
  function openMenu(e){
	  const w = ref.current.offsetLeft + 260 + 10;
	  const h = (ref.current.offsetTop - window.scrollY) + 244 + 50;
	  
	  //var h = palette.height() + (e.pageY - $(window).scrollTop()) + 50;
	  
	 // console.log(h, window.innerHeight);
	  
	  if(w > window.innerWidth && h > window.innerHeight){
		  setPaletteStyle({right: 0,bottom:50});
	  }
	  else if(w > window.innerWidth){
		  setPaletteStyle({right: 0});
	  }	
	  else if(h > window.innerHeight){
		  setPaletteStyle({bottom: 50});
	  }
	  else{
		  setPaletteStyle({});
	  }
	  
	  setIsMenuOpen(true);
  }
  
  
  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (isMenuOpen && ref.current && !ref.current.contains(e.target)) {
    	  //console.log("Clicked ....")
    	  setIsMenuOpen(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
    	
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [isMenuOpen]);

  if(data.isEditable == 0 || !value){
	  return <></>;
  }
  
  return (
	
	<li onClick={openMenu}  ref={ref} >
	    <div>
	    	<div className="select-btn">
	        	<div data-type="btn" data-value={value} data-target={data.target} data-target-type={data.type}  data-key={data.key} style={{backgroundColor: value}}>
	        		<div data-type="arrow"></div>
	        	</div>
	    	</div>
	        
	        <div className="color-heading">{data.name}</div>
	    </div>
	    
	    {isMenuOpen && (
	    		<div className="palette" style={paletteStyle}>

			
	    		{Object.entries(colors).map(([color, name],c) => (
					
	    				<div key={c+"-"+index} style={{backgroundColor:color}} onClick={(e) => {setColor(color)}}>
							<span>{name}</span>
						</div>
				
	            ))}
	    		</div>
	    )}
	</li>
	
  );
}

export default Color;
//export {colors_list_key};
