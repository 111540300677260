import { useState } from "react";
//const PUBLIC_URL = process.env.PUBLIC_URL;


	
const NoteModal = (props) => { 
  
	const home = props.home;
	const [agree, setAgree] = useState(false);
	const [note, setNote] = useState("");
	
	if(!props.show){
		return <></>;
	}
	
	function ok(){
		if(agree){
			props.close();
		}
	}
	
	function handleChange(e){
		setAgree(e.target.checked)
	}
	
	const handleNoteChange = (event) => {
		setNote(event.target.value);
		home.setState({note:event.target.value});
    };
	
    
  return (
		  <>
		  <div className="modal show" id="note-model" aria-modal="true" role="dialog" style={{display: "block", "paddingLeft": "0px"}}>
			<div className="modal-dialog modal-dialog-centered">
				<div className="modal-content custom-modal">

					
					<div className="modal-header">
						<h4 className="modal-title">Note to designers!</h4>
						<button type="button" className="btn-close" onClick={props.close}></button>
					</div>

					
					<div className="modal-body designerstyle">
						<p>We welcome any additional information to make your custom
							products design exactly how you would like it to be. If you have
							any special instructions or want to clarify something, simply add a
							note here.</p>

						<textarea value={note} placeholder="Type your instructions here" onChange={handleNoteChange}></textarea>


						<div className="checkbox mb10">
							<label>I understand and accept these conditions of copyright. 
								<input type="checkbox" onChange={handleChange} checked={agree} />
								<span className="checked"></span>
							</label>
						</div>

						<div className="action-button">
							<button className="btn btn-ok" onClick={ok}>OK</button> 
							<button className="btn btn-cancel" onClick={props.close}>Cancel</button>
						</div>

					</div>
				</div>
			</div>
		</div>
		<div className="modal-backdrop show"></div>
		</>
	
  );
}

export default NoteModal;
