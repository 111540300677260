import React, { useState } from "react";
import SelectBoxComponent from "./SelectBoxComponent";
import ColorComponent from "./ColorComponent";

import ImageBuilderModal from "./SaveModalComponent"; 
import UploadModalComponent from "./UploadModalComponent"; 
import EmailModalComponent from "./EmailModalComponent"; 
import $ from 'jquery';

//import {setStateAsync} from "../lib/Pearsox";

const PUBLIC_URL = process.env.PUBLIC_URL;




function Leftside(props) {

	const home = props.home;

	
	async function changeColor(color,index){
	
		let layers = home.state.pattern.layers;
		const pattern_id = home.state.pattern.id;
		
		//await home.history.setDefaultLayers(pattern_id,layers);
		
		let layer = layers[index]
		layer.color = color;
		$("."+layer.key).css(layer.type,color);
		
		//await home.history.pushLayers(pattern_id,layers);
		
		home.setState({undoRedo:home.history.getUndoRedo()});
		//home.setState({undoRedo:home.history.getUndoRedo()});
		//console.log("Undo Redo : ", home.state.undoRedo);
		//console.log("Push Layers : ", layers,index);
	}
	
	
	
	async function changeTextColor(color,index){
		let temp = home.state.builderTextColor;
		temp.color = color;
		
		home.setState({builderTextColor:temp});
		//console.log("change text color : ",temp);
	}
	
	async function builderTextChange(e){
		 home.setState({builderText:e.target.value});
		 $(".text-draggable-container").show();
		 $("#dragText").width("auto");
		 $("#text-dragable").width("auto");
	}
	
	async function logoTextChange(isLogo){
		home.setState({isLogo:isLogo});
		
		if(isLogo === false){
			document.querySelector('.text-draggable-container').setAttribute("style", "display:block;");
		}
		else{
			setTimeout(() => {
				 
					const dragImage = document.querySelector('.dragImage').getAttribute("src");
					console.log("drageimage : ",dragImage);
					if(dragImage !== ''){
						document.querySelector('.draggable-container').setAttribute("style", "display:block;");
					}
					else{
						document.querySelector('.draggable-container').setAttribute("style", "display:none;");
					}
			},5);
		}
	}
	
	

	//for upload logo modal
	const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
	
	function UploadClose(){
		setIsUploadModalOpen(false);
	}
	
	//for Image builder modal and save image
	const [isImageBuilderModalOpen, setIsImageBuilderModalOpen] = useState(false);
	
	function imageBuilderModalClose(){
		setIsImageBuilderModalOpen(false);
	}

	const [isEmailModalOpen, setIsEmailModalOpen] = useState(false);
	function EmailClose(){
		setIsEmailModalOpen(false);
	}
	
  return (
		  <div className="col col-md-6 col-lg-3 left-sidebar" id="left-sidebar">
		  
		 
		  
		  <ImageBuilderModal filename={home.state.product.name+"-"+home.state.pattern.name} show={isImageBuilderModalOpen} close={imageBuilderModalClose}></ImageBuilderModal>
		  <UploadModalComponent show={isUploadModalOpen} close={UploadClose} home="home"></UploadModalComponent>
		  <EmailModalComponent show={isEmailModalOpen} close={EmailClose}></EmailModalComponent>

          <section>
          
          		{home.state.categories &&
          			<SelectBoxComponent changeHandle={home.handleOnCategory} title={home.state.categories.title} data={home.state.categories.data} value={home.state.product.category_id} ></SelectBoxComponent>
          		}
          		
          		{(home.state.products && home.state.current_product_list) &&
		      		<SelectBoxComponent changeHandle={home.handleOnProduct} title={home.state.products.title} data={home.state.current_product_list} value={home.state.product.id} ></SelectBoxComponent>
		      	
          		}
          		
          		{(home.state.product.style_name && home.state.product.style_values_select) &&
          			<SelectBoxComponent changeHandle={home.handleOnStyleKey}  title={home.state.product.style_name} data={home.state.product.style_values_select} value={home.state.product.style_values_select[home.state.product.pattern_key]}></SelectBoxComponent>
          		}

				<div id="select-pattern" className="select-box">
                  <div className="heading">Select Pattern</div>
                  <div id="horizontal-images1" className="horizontal-images scroll">
                      <ul>
                      
                      {home.state.current_pattern_list && home.state.current_pattern_list.map((row, index) => (
                          <li className={(home.state.pattern.id === row.id)?'active':''} key={index} onClick={() => home.setActivePatterns(home.state.current_pattern_list,index)}>
                               <div className="pattern-sec" style={{width:"82px",height:"83px",background:"#fff"}}>
                               	{(home.state.pattern.id === row.id) && 
                               		<div className="active-check"></div>
                               	}
                                   
                                   <img alt="" src={row.builder_image} width="100%" height="100%"/>
                                   <div className="pattern-name"><span>{row.name}</span></div>
                               </div>
                          </li>
                      ))}
                         
                      </ul>
                  </div>
              </div>

              
              
              {(home.state.pattern && home.state.pattern.isLayers) &&
              <>
              <div id="select-color" className="select-box">
                  <div className="heading">Select Color</div>
                  <ul className="color-picker">
                  {Object.keys(home.state.pattern.layers).map((key, index) => ( 
                	  <ColorComponent colors={home.state.product.colors}  key={index} data={home.state.pattern.layers[key]} changeColor={changeColor} index={key}></ColorComponent> 
                  ))}
                  </ul>
              </div>
              
              
              {(home.state.product.is_logotext === 1) &&
	              <>
	              <div id="logotext" className="select-box">
	                  <ul className="option-click">
	                      <li className={(home.state.isLogo === true) ? 'active' : ''}  id="option-logo" onClick={(e) => {logoTextChange(true)}}>
	                          <span>Logo</span>
	                      </li>
	                      <li className={(home.state.isLogo === false) ? 'active' : ''} id="option-text" onClick={(e) => {logoTextChange(false)}}>
	                          <span>Text</span>
	                      </li>
	                  </ul>
	              </div>
	              
	              <SelectBoxComponent title="Location" changeHandle={home.handleOnLocation} value={home.state.product.logotext} data={home.state.product.logotext}></SelectBoxComponent>
	              </>
              }
              </>
              }

				
              
              
				
              {(home.state.product.is_logotext === 1 && home.state.isLogo === true) && (
              
              <div id="select5" className="select-box">
                  <div id="upload-section" className="option-logo">
                      <div className="upload-btn-wrapper">
                          <button className="btn" onClick={(e) => {setIsUploadModalOpen(true)}}>
                          	<svg className="upload-icon" id="upload-cloud-2-line" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
								  	<path id="Path_257" data-name="Path 257" d="M0,0H24V24H0Z" fill="none"/>
								  	<path id="Path_258" data-name="Path 258" d="M12,12.586l4.243,4.242-1.415,1.415L13,16.415V22H11V16.413l-1.828,1.83L7.757,16.828ZM12,2a7,7,0,0,1,6.954,6.194A5.5,5.5,0,0,1,18,18.978V16.964a3.5,3.5,0,1,0-1.112-6.911,5,5,0,1,0-9.777,0,3.5,3.5,0,0,0-1.292,6.88l.18.03v2.014A5.5,5.5,0,0,1,5.046,8.194,7,7,0,0,1,12,2Z"/>
								</svg>
                          	
                              <span className="upload-text">Upload</span> 
                              <img alt="" src={PUBLIC_URL + '/assets/images/information.png'} className="information" /> 
                          </button>
                          <input type="file" name="myfile" id="upload-file" accept="image/*" style={{display:"none"}}/>
							
                        </div>
                  </div>
              </div>
             
              )}
              
                          
                          
              
              {(home.state.product.is_logotext === 1 && home.state.isLogo === false) && (  
            	<>
	              <div id="select6" className="select-box option-text">
	                 
	                  <div className="select">
	                  	<input id="builderText" type="text" placeholder="Enter text here" value={home.state.builderText} onChange={(e) => {builderTextChange(e)}}/>
	                  </div>
	              </div>
	              
	             
	              <div id="select7" className="select-box option-text">
	                  <div className="font-color-box">
	                  
	                      <div className="select item">
	                      	<SelectBoxComponent changeHandle={home.handleOnChangeFont} data={home.state.product.logotextFonts} value={home.state.product.logotextFonts['VarsityTeamBold']}></SelectBoxComponent>
	                      </div>
	                      
	                      <ul className="color-picker item">
	                      	<ColorComponent colors={home.state.product.colors} data={home.state.builderTextColor} changeColor={changeTextColor} index='text'></ColorComponent>
	                      </ul>
	                  </div>
	              </div>
	            </>
              )}
             
	                              	
	                              	
              
              <div id="select8" className="select-box update-design" style={{display:"none"}}>
                  	<button>Update Your Design</button>
              </div>

              
				 <div id="select9" className="select-box">
                  <div className="control-data">
                      <ul>
                          
                          <li onClick={(e) => {setIsImageBuilderModalOpen(true)}}>
                              <span>
                                  <svg id="save-2-line" xmlns="http://www.w3.org/2000/svg" width="21.262" height="21.262" viewBox="0 0 21.262 21.262">
                                      <path id="Path_316" data-name="Path 316" d="M0,0H21.262V21.262H0Z" fill="none"/>
                                      <path id="Path_317" data-name="Path 317" d="M4.718,4.718V16.745H16.745v-9.6L14.316,4.718ZM3.859,3H15.027l3.185,3.185a.859.859,0,0,1,.252.607V17.6a.859.859,0,0,1-.859.859H3.859A.859.859,0,0,1,3,17.6V3.859A.859.859,0,0,1,3.859,3Zm6.873,12.886a2.577,2.577,0,1,1,2.577-2.577A2.577,2.577,0,0,1,10.732,15.886ZM5.577,5.577h7.732V9.014H5.577Z" transform="translate(-0.101 -0.101)"/>
                                  </svg>
                                    Save the Design
                              </span>
                          </li>
                          
                          <li onClick={(e) => {setIsEmailModalOpen(true)}}>
	                          <span>
	                              <svg id="mail-send-line" xmlns="http://www.w3.org/2000/svg" width="21.262" height="21.262" viewBox="0 0 21.262 21.262">
	                                  <path id="Path_314" data-name="Path 314" d="M0,0H21.262V21.262H0Z" fill="none"/>
	                                  <path id="Path_315" data-name="Path 315" d="M19.329,17.61a.869.869,0,0,1-.872.853H2.629a.863.863,0,0,1-.872-.853v-.865H17.572V6.694l-7.029,6.185L1.757,5.148V3.859A.869.869,0,0,1,2.636,3H18.451a.869.869,0,0,1,.879.859ZM3.9,4.718l6.648,5.85,6.648-5.85ZM0,13.309H7.029v1.718H0Zm0-4.3H4.393v1.718H0Z" transform="translate(0 -0.101)"/>
	                              </svg>
	                               Email the Design
	                          </span>
	                      </li>
	                      
                      </ul>
                  </div>
               </div>
          </section>
      </div>


  );
}

export default Leftside;




            
            
            
