import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Home from "./components/Home";
import DragAndDrop from "./DragComponent";

function App(){
	const getBasename = path => path.substr(0, path.lastIndexOf('/'));
	
	return (
			<>
	        <Router basename={getBasename(window.location.pathname)}>
	          <Routes>
	          
	         
	            <Route> 
	                <Route path="/" element={<Home />} />
	                <Route path="/drag" element={<DragAndDrop />} />
	            </Route>
	              
	          
	            
	          </Routes>
	        </Router>
	      </>
	);
}

export default App;
