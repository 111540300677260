import { API_URL } from "../config";

import axios from "axios";

import loader from "../components/LoaderComponent";


export class Http {
	
	
	constructor(loaderDisplay=false){
		this.loaderDisplay = loaderDisplay;
		
		this.headers = {};
		this.headers['Content-Type'] = "application/json";	
		
	}
	
	setAuthorization(token){
		//this.headers['x-access-token'] = `Bearer ${token}`;
		this.headers['x-access-token'] = token;
	}
	
	setContentType($type){
		this.headers['Content-Type'] = $type
	}
	
	async post(uri,data,isPrivate = true) {
		return await this.apiCall("post", uri, data, isPrivate);
	}
	
	async get(uri,data,isPrivate = true) {
		return await this.apiCall("get", uri, data, isPrivate);
	}
	
	async put(uri,data,isPrivate = true) {
		return await this.apiCall("put", uri, data, isPrivate);
	}
	
	async del(uri,data,isPrivate = true) {
		return await this.apiCall("delete", uri, data, isPrivate);
	}
	
	loader(show){
		//console.log("Loader....... ",show);
		if(show){
			loader.show();
		}
		else{
			loader.hide();
		}
	}
	
	async apiCall(method,uri,data = null,isPrivate = true) {
		
		/*if(this.loaderDisplay){
			loader.show();
		}*/
		
		var config = {
			    method,
			    url: `${API_URL}/${uri}`
			};
		
		if(method === 'get'){
			config["params"] = data;
		}
		if(data){
			config["data"] = JSON.stringify(data);
		}
		  
		if(isPrivate) {
			config["headers"] = this.headers;
		}
		else{
			config["headers"] = this.headers;
			delete config["headers"]['x-access-token'];
			
		}
		//console.log("config", config);
		var r =  await axios(config);
		
		/*setTimeout(() => {
			loader.hide();
		}, 500);*/
		
		return r;
			  
	}
	
	
	async childProduct(session,sku) {
		
		const url = "https://pearsox.com/data/child-products-by-parent/"+sku;
		const authorization = "DEA67B8F2BCF407DAE4A4461C46439C3";
			
			
		if(this.loaderDisplay){
			loader.show();
		}
		
		var config = {
			    headers: {'Access-Control-Allow-Origin': '*',crossdomain: true,'token':session,'authorization':authorization},
			    //data:JSON.stringify(data)
			};
		//delete axios.defaults.headers.common["X-Requested-With"];
		var r =  await axios.get(url,config);
		
		//console.log("response place order : ",r );
		
		setTimeout(() => {
			loader.hide();
		}, 500);
		
		return r;
			  
	}
	
	async placeOrder(url,data) {
		
		if(this.loaderDisplay){
			loader.show();
		}
		
		var config = {
			   // headers: {'Access-Control-Allow-Origin': '*',crossdomain: true},
			    data:JSON.stringify(data)
			};
		//delete axios.defaults.headers.common["X-Requested-With"];
		var r =  await axios.post(url,config);
		
		console.log("response place order : ",r );
		
		setTimeout(() => {
			loader.hide();
		}, 500);
		
		return r;
			  
	}
	
	async load(url) {
		
		if(this.loaderDisplay){
			loader.show();
		}
		
		/*var config = {
			    headers: {'Access-Control-Allow-Origin': '*',crossdomain: true}
			};*/
		//delete axios.defaults.headers.common["X-Requested-With"];
		var r =  await axios.post(url);
		
		
		
		setTimeout(() => {
			loader.hide();
		}, 500);
		
		return r;
			  
	}
	
	
	
}


