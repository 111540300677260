import React from "react";
import $ from 'jquery';
import 'jquery-ui';
import 'jquery-ui/ui/widgets/draggable';
import 'jquery-ui/ui/widgets/resizable';
import 'jquery-ui/ui/widgets/mouse';
//import 'jquery-ui/ui/widgets/rotatable';

//const PUBLIC_URL = process.env.PUBLIC_URL;
function touchHandler(event) {
    var touch = event.changedTouches[0];

    var simulatedEvent = document.createEvent("MouseEvent");
        simulatedEvent.initMouseEvent({
        touchstart: "mousedown",
        touchmove: "mousemove",
        touchend: "mouseup"
    }[event.type], true, true, window, 1,
        touch.screenX, touch.screenY,
        touch.clientX, touch.clientY, false,
        false, false, false, 0, null);

    touch.target.dispatchEvent(simulatedEvent);
    
}

let home;
export default class ImageDrag extends React.Component{
	
	constructor(props) {
	    super(props);
	    this.home = props.home;
		home = this.home;
	}
	
	
	componentDidMount() {
		
		document.addEventListener("touchstart", touchHandler, true);
	    document.addEventListener("touchmove", touchHandler, true);
	    document.addEventListener("touchend", touchHandler, true);
	    document.addEventListener("touchcancel", touchHandler, true);
	    let first = true;

		$("#image-dragable").draggable({
			handle: ".imageDragHandler",
			start: function( event, ui ) {
				let drag_data = $("#image-dragable").attr('style');
				return new Promise( (resolve) =>
					changeDrag('IMAGEDRAG',drag_data,resolve)
				);
			},
			stop: function( event, ui ) {
				let drag_data = $("#image-dragable").attr('style');
				return new Promise( (resolve) =>
					changeDrag('IMAGEDRAG',drag_data,resolve)
				);
			}
		}).rotatable();


		$("#image-dragable").resizable({
			autoHide:false,
			aspectRatio: true,
			start: function(e, ui) {
	        	if(first === true){
	        		first = false;
	        		let drag_data = $("#image-dragable").attr('style');
		            return new Promise( (resolve) =>
			            changeDrag('IMAGERESIZE',drag_data,resolve)
			        );
	        	}
	        },
	        stop: function(e, ui) {
	        	let drag_data = $("#image-dragable").attr('style');
	            return new Promise( (resolve) =>
		            changeDrag('IMAGERESIZE',drag_data,resolve)
		        );
	        }
		});
        
		$("#image-dragable .drag_close").click(function(){
			$(".image-draggable-container").hide();
			document.querySelector('.dragImage').setAttribute("src",'');
		});


		async function changeDrag(type,data){
			await home.history.pushTextBox(home.state.pattern.id,type,data);
			home.setState({undoRedo:home.history.getUndoRedo()});
		}
    }
	
	
	render() {
		return (
				<div id="image-draggable-container" className="image-draggable-container">
				
					 <div id="image-dragable" className={'image-draggable'}>
					 
						<button type="button" className="btn-actions">
						  <span className="drag_close">x</span>
						</button>
						
						<div className="imageDragHandler">
							<><img className="dragImage" src="" alt=""/></>
						</div>
						
					  </div>
				  
				  </div>
				);
	}

}