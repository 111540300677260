

const setStateAsync = ( obj, state ) => {
    return new Promise( ( resolve ) =>
        obj.setState( state , resolve )
    )
}

class PearsoxModel {
	
	http;
	session;
	sku;
	
	categories;
	products;
	product;
	childProducts; // sizes list - fetch from pearsox api
	pattern;
	
	
	
	constructor(http, session,sku) {
		this.http = http;
		this.session = session;
		this.sku = sku;
		
		//console.log("Product Model Init ... ",session, sku);		
	}
	
	async init(){
		
		this.http.loader(true);
		
		this.categories = await this.fetchAPI("category/list");
		this.products = await this.fetchAPI("product/list");
		this.product = await this.fetchAPI("product/"+this.sku);
		
		if(this.product === false){
			//console.log("Product : ", this.product);
			return {
				categories:this.categories,
				products:this.products,
				product:this.product
			}
		}
		
		this.childProducts = await this.fetchChildProduct(this.session, this.sku);
		
		let pattern_key = this.getDefaultPatternKey();
		let current_pattern_list = this.product.patterns[pattern_key];
		
		this.http.loader(false);
		
		return {
			categories:this.categories,
			products:this.products,
			
			product:this.product,
			category_id:this.product.category_id,
			
			pattern_key:pattern_key,
			pattern:this.getDefaultPattern(pattern_key),
			current_pattern_list: current_pattern_list,
			
			current_product_list:this.getCurrentProductList(),
			childProducts:this.childProducts,
			
			location:this.getFirstLocation(this.product.logotext),
		};
	}
	
	async setProduct(sku){
		this.http.loader(true);
		
		this.product = await this.fetchAPI("product/"+sku);
		this.childProducts = await this.fetchChildProduct(this.session, this.sku);
		
		this.sku = sku;
		
		let pattern_key = this.getDefaultPatternKey();
		let current_pattern_list = this.product.patterns[pattern_key];
		
		this.http.loader(false);
		
		return {
			product:this.product,
			category_id:this.product.category_id,
			pattern_key:pattern_key,
			pattern:this.getDefaultPattern(pattern_key),
			current_pattern_list: current_pattern_list,
			
			current_product_list:this.getCurrentProductList(),
			childProducts:this.childProducts,
			
			location:this.getFirstLocation(this.product.logotext),
		};
	}
	
	getFirstLocation(logotext){
		for (const [key,row] of Object.entries(logotext)) {
			return key;
		}
			
	}
	
	getDefaultPatternKey(){
		if(this.product.patterns && this.product.style_name == null){
			return 'default';
		}
		else if(this.product.patterns && this.product.style_name != null){
			
			if(this.product.style_values.length > 0){
				return this.product.style_values[0];
				//let [key, value] = Object.entries(this.product.style_values)[0]; 
			}
		}
		return '';
	}
	
	getDefaultPattern(pattern_key){
		try{
			if(this.product.patterns && this.product.patterns[pattern_key]){
				
				if(this.product.patterns[pattern_key].length > 0){
					//console.log("default pattern - ",this.product.patterns[pattern_key][0]);
					return this.product.patterns[pattern_key][0];
				}
				else{
					return false;
				}
				
			}
			else{
				console.log("current pattern not found!")
				return false;
			}
		} catch (exceptionVar) {
			console.log("current pattern not found!")
			return false;
		}
		
	}
	
	getCurrentProductList(){
		try{
			if(this.products.data && this.products.data[this.product.category_id]){
				return this.products.data[this.product.category_id];
			}
			else{
				console.log("current product not found!")
				return false;
			}
		} catch (exceptionVar) {
			console.warn("current product not found error!")
			return false;
		}
	}
	
	
	
	
	//API fetch and store
	async fetchAPI(uri){
		return await this.http.get(uri)
		.then((res) => {
			if (res.data) {
				return res.data;
			}
			else{
				return false;
			}
		}).catch(error => {
		    return false;
		});
	}
	

	async fetchChildProduct(session,sku){
		
		const result = await this.fetchAPI("product/childProduct/"+session+"/"+sku);
		
		if(result.Data){
			return result.Data;
		}
		else{
			return false;
		}
	}
	
	
};


export default PearsoxModel;
export {setStateAsync};
