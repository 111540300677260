import { useEffect, useState } from "react";
import $ from 'jquery';	


const PUBLIC_URL = process.env.PUBLIC_URL;


const FaqModal = (props) => { 
  
	const home = props.home;
	
	const [faq, setFaq] = useState(false);
	
	if(!props.show){
		return <></>;
	}
	
	if(!faq){
		home.http.get("faq")
		.then((res) => {
			if (res.data) {
				setFaq(res.data);
				//console.log("Fetch Faq : ", res.data);
				
				/*$('.collapsed').on('click', function(e) {
				    console.log("Collapased......");
				})*/
			}
		});
		
		//return <></>;
	}
	
	
	
  
  return (
		  <>
		  <div className="modal show" id="faqmodal" aria-modal="true" role="dialog" style={{display: "block", "paddingLeft": "0px"}}>


		  	<div className="modal-dialog modal-lg  modal-dialog-centered">
		  		<div className="modal-content custom-modal">

		  			
		  			<div className="modal-header">
		  				<h4 className="modal-title">Frequently Asked Questions</h4>
		  				<button type="button" className="btn-close" onClick={props.close}></button>
		  			</div>

		  			{faq &&
		  			<div className="modal-body" id="faq_accordion">
		  				<div className="accordion">
		  				
		  				
		  				 {faq.map((row, index) => (
		  						
		  					<div className="accordion-item" key={row.id}>
		  					
		  						<h2 className="accordion-header" id={"h-"+row.id}>
		  							<button className="accordion-button collapsed" type="button"
		  								data-bs-toggle="collapse" data-bs-target={"#c-"+row.id}
		  								aria-expanded="false" aria-controls={"c-"+row.id}>
		  								<span className="ask-question"> <img alt="" src={PUBLIC_URL + '/assets/images/ask-question.png'}/></span> 
		  								{row.question}
		  							</button>
		  						</h2>
		  						
		  						<div id={"c-"+row.id} className="accordion-collapse collapse"
		  							aria-labelledby={"h-"+row.id} data-bs-parent="#faq_accordion">
		  							<div className="accordion-body">
		  								{row.answer}
		  							</div>
		  						</div>
		  					</div>
		  					
		  				))}
		  				 
		  					
		  					
		  					
		  				</div>

		  			</div>}
		  			
		  			
		  			
		  		</div>
		  			
		  			
		  	</div>
		  </div>
		 

		<div className="modal-backdrop show"></div>
		</>
	
  );
}

export default FaqModal;
