import React from "react";
import $ from 'jquery';
import 'jquery-ui';
import 'jquery-ui/ui/widgets/mouse';
import 'jquery-ui/ui/widgets/draggable'
import 'jquery-ui/ui/widgets/resizable'

import 'jquery-ui-rotatable';


//const PUBLIC_URL = process.env.PUBLIC_URL;
function touchHandler(event) {
    var touch = event.changedTouches[0];

    var simulatedEvent = document.createEvent("MouseEvent");
        simulatedEvent.initMouseEvent({
        touchstart: "mousedown",
        touchmove: "mousemove",
        touchend: "mouseup"
    }[event.type], true, true, window, 1,
        touch.screenX, touch.screenY,
        touch.clientX, touch.clientY, false,
        false, false, false, 0, null);

    touch.target.dispatchEvent(simulatedEvent);
    
}

let home;

export default class TextDrag extends React.Component{
	
	constructor(props) {
	    super(props);
	    
	    this.home = props.home;
	    home = this.home;
	    
	    //console.log("home : ", this.home);
	    
	    this.state = {
		    	rotate:0,
		    	fontSize:30
		};
	    
	}
	
	
	
	componentDidMount() {
		
		document.addEventListener("touchstart", touchHandler, true);
	    document.addEventListener("touchmove", touchHandler, true);
	    document.addEventListener("touchend", touchHandler, true);
	    document.addEventListener("touchcancel", touchHandler, true);
	
	
		let text_dragable = $("#text-dragable");
		let initDiagonal = (100*50)+(100*50);
		let initFontSize = 6;
		
		$("#builderText").change(function(){
			initDiagonal = getContentDiagonal();
			//initFontSize = parseInt($("#dragText").css("font-size"));
		});
		
		text_dragable.draggable({ 
					handle: ".textDragHandler",
					start: function( event, ui ) {
						let drag_data = $("#text-dragable").attr('style');
			            return new Promise( (resolve) =>
			            	changeDrag('TEXTBOXDRAG',drag_data,resolve)
				        );
					},
					stop: function( event, ui ) {
						let drag_data = $("#text-dragable").attr('style');
			            return new Promise( (resolve) =>
			            	changeDrag('TEXTBOXDRAG',drag_data,resolve)
				        );
					}
				}).rotatable();
		//text_dragable.resizable({autoHide:true,alsoResize: "#dragText" });
		
		let first = true;
		
		$("#text-dragable").resizable({
	        alsoResize: '#dragText',
			minWidth: 100,
			minHeight: 40,
	        create: function(event, ui) {
	            //initDiagonal = getContentDiagonal();
	            //initFontSize = parseInt($("#dragText").css("font-size"));
	            //console.log("Create : ",initDiagonal,initFontSize);
	            
	            //$("#dragText").css("width","auto");
	            //$("#dragText").css("height","auto");
	        },
	        resize: function(e, ui) {
	        	ui.size.height = Math.round(ui.size.height / 10) * 10;
	        	ui.size.width = Math.round(ui.size.width / 10) * 10;
	        	

	            var newDiagonal = getContentDiagonal();
	            var ratio = newDiagonal / initDiagonal;
	            //console.warn("Resize : ",newDiagonal,initDiagonal,ratio, " : ", initFontSize + (ratio * 3));
	            $("#dragText").css("font-size", initFontSize + (ratio * 3));
				//console.log("Resize : ",$("#dragText").width(),$("#dragText").height());

				//let size = ui.size.width * 0.16;
				//$("#dragText").css("font-size", size + "px");

				$("#text-dragable").width($("#dragText").width()+15).height($("#dragText").height()+10);
				//$("#text-dragable").width($("#dragText").width()+30).height($("#dragText").height()+20);
	        },
	        start: function(e, ui) {
	        	//console.log("first data : ",first);
	        	if(first === true){
	        		//console.log("First drag......");
	        		first = false;
	        		let drag_data = $("#dragText").attr('style');
		            return new Promise( (resolve) =>
			            changeDrag('TEXTBOXRESIZE',drag_data,resolve)
			        );
	        	}
	        },
	        stop: function(e, ui) {

				//let size = ui.size.width * 0.16;
				//$("#dragText").css("font-size", size + "px");
				//$("#text-dragable").width($("#dragText").width()+30).height($("#dragText").height()+20);


	        	let drag_data = $("#dragText").attr('style');
	            return new Promise( (resolve) =>
		            changeDrag('TEXTBOXRESIZE',drag_data,resolve)
		        );
	        }
	    });
		
		async function changeDrag(type,data){
			await home.history.pushTextBox(home.state.pattern.id,type,data);
			home.setState({undoRedo:home.history.getUndoRedo()});
		}
		
		
		function getContentDiagonal() {
		    var contentWidth = $("#dragText").width();
		    var contentHeight = $("#dragText").height();
		    
		    //console.log("Diagonal of Text: ", contentWidth,contentHeight);
		    
		    //console.log("test",contentWidth,contentHeight, contentWidth * contentWidth + contentHeight * contentHeight);
		    return ((contentWidth * contentWidth) + (contentHeight * contentHeight));
		}
        
		$("#text-dragable .drag_close").click(function(){
			$(".text-draggable-container").hide();
		});
    }
	
	render() {
		return (
				<div className="text-draggable-container" style={{display:"none"}}>
				
					 <div id="text-dragable" className={'text-draggable'}>
						<button type="button" className="btn-actions">
						  <span className="drag_close">x</span>
						</button>
						
						<div className="textDragHandler">
							<div className="dragText" id="dragText" style={{color:this.home.state.builderTextColor.color,fontFamily:this.home.state.builderTextColor.fontFamily,rotate:this.state.rotate+"deg",fontSize:this.state.fontSize+"px"}}>
								{this.home.state.builderText}
							</div>
								
						</div>
					    
					    	
					  </div>
				  
				  </div>
				);
	}

}