import { useState } from "react";
const PUBLIC_URL = process.env.PUBLIC_URL;


	
const GetInTouchModal = (props) => { 
  
	
	
	if(!props.show){
		return <></>;
	}
	
	function ok(){
		props.close();
	}
	
    
  return (
		  <>
		  <div className="modal show" id="getintouch-model" aria-modal="true" role="dialog" style={{display: "block", "paddingLeft": "0px"}}>
			<div className="modal-dialog modal-dialog-centered">
				<div className="modal-content custom-modal getintouch">
					
					<div className="modal-header">
						<button type="button" className="btn-close" onClick={props.close}></button>
					</div>

					<div className="modal-body designerstyle row">
						<div className="col col-md-12">
							<h4>Get In Touch</h4>
							<p>Fill out the information below and click ‘Submit’.</p>
							<ul className="info">
								<li><a href="tel:610-696-1104"><img alt="" src={PUBLIC_URL + '/assets/images/ringer.png'}/> (610) 696-1104</a></li>
								<li><a href="tel:610-696-4627"><img alt="" src={PUBLIC_URL + '/assets/images/phone.png'}/> 1-800-776-9787</a></li>
								<li><a href="tel:610-696-4627"><img alt="" src={PUBLIC_URL + '/assets/images/fax.png'}/> (610) 696-4627</a></li>
								<li><a href="mailto:sales@pearsox.com"><img alt="" src={PUBLIC_URL + '/assets/images/mail.png'}/> SALES@PEARSOX.COM</a></li>
								<li className="width100"><img alt="" src={PUBLIC_URL + '/assets/images/map-line.png'}/> 711 EAST UNION ST, WEST CHESTER PA, 19382</li>
							</ul>

							<p className="social-title">Find us on:</p>
							<ul className="social">
								<li><a target="_blank" href="https://www.facebook.com/pearsoxcorporation/"><img alt="" src={PUBLIC_URL + '/assets/images/facebook.png'}/></a></li>
								<li><a target="_blank" href="https://twitter.com/pearsox_corp"><img alt="" src={PUBLIC_URL + '/assets/images/twitter.png'}/></a></li>
								<li><a target="_blank" href="https://www.instagram.com/pearsox_/"><img alt="" src={PUBLIC_URL + '/assets/images/instagram.png'}/></a></li>
							</ul>
						</div>
						
						

					</div>
				</div>
			</div>
		</div>
		<div className="modal-backdrop show"></div>
		</>
	
  );
}

export default GetInTouchModal;
