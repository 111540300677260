import React, {} from "react";
import loader from "./LoaderComponent";


const PreviewModal = (props) => {

	if(!props.show){
		return <></>;
	}
	const filename = props.filename;
	//console.log(filename);
	
	//loader.show();

	return (
			  <>
			  <div className="modal show" id="previewmodal" aria-modal="true" role="dialog" style={{display: "block", "paddingLeft": "0px"}}>

			  	<div className="modal-dialog modal-lg  modal-dialog-centered">
			  		<div className="modal-content custom-modal">
					  <div className="modal-header">
		  				<h4 className="modal-title">Sample Design</h4>
		  				<button type="button" className="btn-close" onClick={props.close}></button>
		  			</div>

			  			<div className="modal-body designerstyle">
							  <div id="saveImg">
							  	<img id="preview_image" src={filename} alt=""/>
							  </div>
					    </div>
			  		</div>	
			  	</div>
			  </div>
			 

			<div className="modal-backdrop show"></div>
			</>
		
	  );
    
}



export default PreviewModal;
