import { useState } from "react";
const PUBLIC_URL = process.env.PUBLIC_URL;


	
const EmailModal = (props) => { 
  
	const [email, setEmail] = useState('');
	const [error, setError] = useState(false);
	//href="mailto:?subject=Pearsox - Design!&body=Please attach a downloaded image"
	if(!props.show){
		return <></>;
	}
	
	function ok(){
		props.close();
	}

	function validateEmail(val) 
    {
        var re = /\S+@\S+\.\S+/;
        return re.test(val);
    }

	function submit(){
		if(validateEmail(email)){
			setError(false);
			window.location.href="mailto:"+email+"?subject=Pearsox - Design!&body=Please attach a downloaded image";
			props.close();
			setEmail('');
		}
		else{
			setError(true);
		}
		
	}


	
    
  return (
		  <>
		  <div className="modal show" id="getintouch-model" aria-modal="true" role="dialog" style={{display: "block", "paddingLeft": "0px"}}>
			<div className="modal-dialog modal-dialog-centered">
				<div className="modal-content custom-modal getintouch">
					
					<div className="modal-header">
						<button type="button" className="btn-close" onClick={props.close}></button>
					</div>

					<div className="modal-body row">
						<div className="col col-md-12 email-form">
							<h4>Email The Design</h4>
							<p>Please 'Save the Design' and attach in the email body. Add the 'to' address and click 'Compose'</p>
							
							<div className="form-group">
								<label>Email</label>
								<div className="input">
									<img alt="" src={PUBLIC_URL + '/assets/images/mail.png'}/>
									<input value={email} onChange={e => setEmail(e.target.value)} type="email" name="email" placeholder="To Email Address"/>
								</div>
								{error && 
										<span className="error">Please enter your valid email id.</span>
									}
							</div>
							<div className="form-group">
								<button onClick={submit} className="submit">COMPOSE NOW  </button>
								<button onClick={props.close} className="cancel">CANCEL</button>
							</div>

						</div>
						
						

					</div>
				</div>
			</div>
		</div>
		<div className="modal-backdrop show"></div>
		</>
	
  );
}

export default EmailModal;
