import React from "react";
//import { useLocation } from "react-router-dom";

import { Outlet } from "react-router";
import { Http } from "../services/http";
//import XMLParser from "react-xml-parser";
//import loader from "./LoaderComponent";

import HeaderComponent from "./HeaderComponent";
import LeftsideComponent from "./LeftsideComponent";
import MiddleComponent from "./MiddleComponent";
import RightsideComponent from "./RightsideComponent";
//import SelectBoxComponent from "./SelectBoxComponent";
// - https://github.com/bokuweb/react-rnd for draging and resizing

import History from '../lib/History';
import PearsoxModel,{setStateAsync} from "../lib/Pearsox";
import $ from 'jquery';


class Home extends React.Component{
	
	pearsoxModel;
	history;
	
	constructor() {
	    super();
	    
	    this.http = new Http();
	    
	    const url = new URL(window.location.href);
		const session = url.searchParams.get('session');
		const sku = url.searchParams.get('sku');
		const redirect = url.searchParams.get('redirect');
	   
	    
	    this.state = {
	    	session:session,
	    	sku:sku,
			redirect:redirect,
	    	undoRedo:{undo:false,redo:false},
	    	isLogo:true,
	    	builderText:'',
	    	builderTextColor:{key: "text", name: "COLOR",fontFamily:"Varsity Team Bold", color: "#000000", type: "fill"}
	    };
	    
	    this.handleOnCategory = this.setCategoryId.bind(this);
	    this.handleOnProduct = this.setProductId.bind(this);	
	    this.handleOnStyleKey = this.setStyleKey.bind(this);	
	    this.handleOnChangeFont = this.handleOnChangeFont.bind(this);
	    this.handleOnLocation = this.handleOnLocation.bind(this);
	    
	    //console.log("Home init.....");
	    
	    this.http.loader(true);
	  }
	
	sleep(ms) {
	    return new Promise(resolve => setTimeout(resolve, ms));
	}
	
	
	UNSAFE_componentWillMount(){
		
		this.history = new History();
		
		let pearsoxModel = new PearsoxModel(this.http,this.state.session,this.state.sku);
		this.pearsoxModel = pearsoxModel;
		
		
		(async() => {
				const result = await pearsoxModel.init();
				if(result){
					if(result.product === false){
						this.setState({notfound:true})
						this.http.loader(false);
					}
					await setStateAsync(this,result);
				}
				else{
					this.setState({notfound:true})
					//console.log("not found")
					this.http.loader(false);
				}
				
		})(PearsoxModel)
	}
	
	async handleOnChangeFont(row){
		//console.log("chnage font - ",row);
		if(row){
			let temp = this.state.builderTextColor;
			temp.fontFamily = row.id;
			
			this.setState({builderTextColor:temp});
		}
		
	}
	
	async handleOnLocation(row){
		//console.log("chnage font - ",row);
		if(row){
			this.setState({location:row.id});
		}
		
	}
	
	
	async setProductId(row){
		//console.log("change product id : ", this.state.product.id,row.id);
		
		if(this.state.product.id && this.state.product.id !== row.id){
			let uri = "?session="+this.state.session+"&sku="+row.sku;
			if(this.state.redirect){
				uri = uri+"&redirect="+this.state.redirect;
			}
			//alert("uri : "+uri);
			window.location.href = uri;
			
			//console.log("change product id : ", this.state.product_id,row.id);
			let result = await this.pearsoxModel.setProduct(row.sku)
			
			
			await setStateAsync(this,result);
		}
		
	}
	
	async setStyleKey(row){
		//console.log("Change style : ", row);
		if(row && this.state.pattern_key !== row.id){
			let current_pattern_list = this.state.product.patterns[row.id];
			let pattern = current_pattern_list[0];
			//console.log("Current pattern : ",current_pattern_list);
			
			await setStateAsync(this,{pattern_key:row.id,pattern:pattern,current_pattern_list:current_pattern_list});
			//this.setState({pattern_key:row.id,current_pattern_list:this.product.patterns[row.id]});
		}
	}
	
	
	async setCategoryId(row){
		//console.log(this.state.products);
		
		if(this.state.category_id !== row.id){
			
			if(row.id == "1"){
				let temp = [];
				for (let [key, value] of Object.entries(this.state.products.data)) {
					
					for (let [key1, value1] of Object.entries(value)) {
						//console.log("Iterate set product list : ", key1,value1);
						temp.push(value1);
					}
				}
				this.setState({category_id:row.id,current_product_list:temp});
			}
			else{
					let temp = this.state.products.data[row.id];
					//console.log("First Product : ",this.state.products.data, temp, row.id);
					this.setState({category_id:row.id,current_product_list:temp});
				
					let [key, value] = Object.entries(temp)[0];
					//console.log("First Product : ", key, value);
					await this.setProductId(value);
				
				}
		}
	}
	
	
	async setActivePatterns(patterns,index){
		
		if(patterns){
			
			let pattern = patterns[index];
			
			if(pattern){
				await setStateAsync(this,{pattern:pattern});
				//console.log("colors : ",this.state.pattern.layers);
				
				let histories_session = [];
				
				let lastLayers = this.state.pattern.layers;
				for(let k in lastLayers){
					let row = lastLayers[k];
					//console.log("clicked :",row.key,row.type,row.color);
					$("."+row.key).css(row.type,row.color);
				}
				
				for(let k in pattern.layers){
					//pattern.layers.forEach(function (layer, index) {
					//console.log(layer, index);
					
					histories_session.push({type:"color",data:JSON.stringify(pattern.layers[k])});
				}
				
				await setStateAsync(this,{histories:histories_session});
				
			}
			else{
				await setStateAsync(this,{pattern:false});
			}
		}else{
			await setStateAsync(this,{pattern:false});
		}
	}
	
	
	
	
	
	render() {
		  return (
		    <>
		      {(this.state.product && this.state.pattern)?(
		    		  <>
		    		  <HeaderComponent></HeaderComponent>
		    		  <div className="container">
				      	<div className="row main-container">
					      	<LeftsideComponent home={this}></LeftsideComponent>
					      	<MiddleComponent home={this}></MiddleComponent>
							<RightsideComponent home={this} state={this.state}></RightsideComponent>
				      		
				      	</div>
					  </div>
		    		  </>
		      ):(
	    		  <>
	    		  	{(this.state.notfound) &&
	    		  	   <>
			    		  <HeaderComponent></HeaderComponent>
			    		  <div className="container">
					      	<div className="row main-container">
			      			<h3 style={{'color':'#E91A21'}}>Not Found!</h3>
			      			</div>
			      		   </div>
		      		   </>
	    		  	}
	      		  </>
		      )
		      }
		      
		      <Outlet />
		    </>
		  );
	}
};


export default Home;


    
    
    
    
