import React, { useRef,useState } from "react";
//const PUBLIC_URL = process.env.PUBLIC_URL;


	
const UploadModal = (props) => { 
  
	//const home = props.home;
	
	const inputFile = useRef(null) 
	
	
	const [agree, setAgree] = useState(false);
	
	if(!props.show){
		return <></>;
	}
	
	function ok(e){
		if(agree){
			inputFile.current.click();
		}
	}
	
	function handleChange(e){
		setAgree(e.target.checked);
	}
	
	
	const handleFileChange = e => {
        //console.log("Make something",e.target.files)
        
        var file = e.target.files[0];
   	 
        if(file){
            var reader = new FileReader();

            reader.onload = function(){
            	
            	document.querySelector('.dragImage').setAttribute("src", reader.result);
            	
            	//let image = document.querySelector('.dragImage');
            	
            	
            	document.querySelector('.image-draggable-container').setAttribute("style", "display:block;");
            	
            	//console.log("W : ",image.clientWidth, "H : ",image.clientHeight);
            	
            	//document.querySelector('.resize-container').setAttribute("style", "display:block;");
            	//document.querySelector('.drag img').setAttribute("src", reader.result);
            	//document.querySelector('.drag').setAttribute("style", "display:block;width: 100px;position:absolute;");
            	//$(".drag img").attr("src", reader.result);
            }

            reader.readAsDataURL(file);
            //$(".drag").show();
        }
        
        
        props.close();
    }
	


	
	
  return (
		  <>
		  <input type="file" name="myfile" id="upload-file"  onChange={(e) => handleFileChange(e)} ref={inputFile} accept="image/*" style={{display:"none"}}/>
		  
		  <div className="modal show" id="upload-model" aria-modal="true" role="dialog" style={{display: "block", "paddingLeft": "0px"}}>
			<div className="modal-dialog modal-dialog-centered">
				<div className="modal-content custom-modal">

				
					<div className="modal-header">
						<h4 className="modal-title">Uploading photos and Images</h4>
						<button type="button" className="btn-close" onClick={props.close}></button>
					</div>

					<div className="modal-body designerstyle">
						<p>Please note that in order to use a design (photo, image, text, brand, or saying) you must have full rights to use this design. By uploading or saving a design:</p>
						<p>
						Purchaser agrees to fully indemnify and hold Pearsox harmless, from and against any and all liabilities, claims, causes of actions, suits, damages, losses, and expenses (including attorneys’ fees) for which Pearsox may become liable or which it may incur, suffer or pay in any action or as a result of any actual or alleged infringement or violation of any patents, copyrights, trademarks or other rights, including trade secrets and rights of privacy and publicity, in connection with the manufac­ture, distribution, sale, use, advertisement or promotion of any of the Pearsox Products.  In the event that a recall of any Pearsox Products is required, ordered, or recommended by any court or government agency or any applicable law or regulation, for any reason, Purchaser shall comply with such requirement, order, or recommendation and shall bear all the expenses thereof. 
						</p>

						<strong>Graphics Information</strong>
						<p>In the designer, you can upload designs in jpeg, gif, png, and svg formats. All images will need to have a minimum of 150 dpi.</p>

						
						<div className="checkbox mb10">
							<label>I understand and accept these conditions of copyright. 
								<input type="checkbox" onChange={handleChange} checked={agree} />
								<span className="checked"></span>
							</label>
						</div>

						<div className="action-button">
							<button className="btn btn-ok" onClick={ok}>OK</button> 
							<button className="btn btn-cancel" onClick={props.close}>Cancel</button>
						</div>

					</div>
				</div>
			</div>
		</div>
		<div className="modal-backdrop show"></div>
		</>
	
  );
}

export default UploadModal;
