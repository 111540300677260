import { useEffect, useRef, useState } from "react";
//const PUBLIC_URL = process.env.PUBLIC_URL;

//function  Menu({ children }){
	
const Menu = (props) => { 
  
  const ref = useRef();
  
  //console.log("Menu Props : ",props);
  
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  
  //change handler
  const changeHandle = props.changeHandle;
  
  const [title] = useState(props.title);
  const [data,setData] = useState(props.data);
  
  const [currentValue,setCurrentValue] = useState(data[props.value]);
  
  let tempCurrentValue = false;
  
  
  if (props.data !== data) {
	  setData(props.data);
	  //setDefaultValue();
	  
	  //console.log("Data is : ", props.data);
  }
  
  if (props.value !== currentValue) {
	  setDefaultValue();
  }
  
  if(!currentValue){
	  setDefaultValue();
  }
  
  function setDefaultValue(){
	  
	  //console.log("Set default Value : ",props.title, props.value)
	  
	  if(props.value){
		  tempCurrentValue = data[props.value];
		  //setCurrentValue(tempCurrentValue);
	  }
	  
	  if(!tempCurrentValue && data){
		  let c = 0;
		  for (const [key,row] of Object.entries(data)) {
			  if(c == 0){
				  tempCurrentValue = row;
			  }
			  c++;
		  }
	  }
  }
  
  
  
  function setMenu(row) {
	  setIsMenuOpen(wasOpened => !wasOpened);
	  setCurrentValue(row);
	  //console.log("Current menu  : ",row);
	  if(changeHandle){
		  changeHandle(row);
	  }
	  
	  //console.log("Current id : ",props.value);
  }
  
  
  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (isMenuOpen && ref.current && !ref.current.contains(e.target)) {
    	  setIsMenuOpen(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [isMenuOpen]);
  
  
  /*if(!title){
	  return <></>;
  }*/

  return (
    
    <div id="select1" className="select-box mt-0">
    
    	{title && (
    		<div className="heading">{title}</div>
    	)}
	    
	  
	    <div className="select"  ref={ref}>
	    
	    	{currentValue && (
	        <button className="btn-select" onClick={(e) => {setMenu(currentValue)}}>
	        	<li>
	        		{currentValue.icon && (
	        				<img alt="" src={currentValue.icon}/>
	        		)}
	        		<span>{currentValue.title}</span>
	        	</li>
	        </button>
	    	)}
	    	
	    	{!currentValue && (
    	        <button className="btn-select" onClick={(e) => {setMenu(currentValue)}}>
    	        	<li>
    	        		{tempCurrentValue.icon && (
    	        				<img alt="" src={tempCurrentValue.icon}/>
    	        		)}
    	        		<span>{tempCurrentValue.title}</span>
    	        	</li>
    	        </button>
    	    	)}
	        
	        {isMenuOpen && (
	        <div className="select-items" style={{"display": "block"}}> 
	            <ul>
	            
	            	{Object.entries(data).map(([key, row], index) =>
		            		<li onClick={(e) => {setMenu(row)}} key={index}>
			            		{row.icon && (
				        				<img alt="" src={row.icon}/>
				        		)}
		            			<span>{row.title}</span>
		            		</li>
	            	)}
	            
	            </ul>
	        </div>
	        )}
	        
	    </div>
	</div>


  );
}

export default Menu;
