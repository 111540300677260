//import { useEffect, useRef, useState } from "react";
//const PUBLIC_URL = process.env.PUBLIC_URL;


	
const SizeChartModal = (props) => { 
  
	if(!props.show){
		return <></>;
	}
	
	const data = props.data; 
	
  
  return (
		  <>
		  <div className="modal show" id="size-chart-modal" aria-modal="true" role="dialog" style={{display: "block", "paddingLeft": "0px"}}>
		  
			<div className="modal-dialog modal-lg  modal-dialog-centered">
				<div className="modal-content custom-modal">

					
					<div className="modal-header">
						<h4 className="modal-title">Size Chart</h4>
						<button type="button" className="btn-close" onClick={props.close}></button>
					</div>

					
					<div className="modal-body scroll">
						<table className="table table-striped table-chart-size">
						
						<thead>
						<tr>
							{data && data.heads.map((v, index) => (
								<th key={index}>{v}</th>
							))}
						</tr>
						</thead>
						
							<tbody>
								{data && data.rows.map((row, index) => (
									<tr key={index}>
										{row.map((v, index) => (
											<td key={index}>{v}</td>
										))}
									</tr>
								))}

							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
		<div className="modal-backdrop show"></div>
		</>
	
  );
}

export default SizeChartModal;
