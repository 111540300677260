//import React,{} from "react";
//import $ from 'jquery';

let zoomValue = 1;
let limit = 0;


//Builder Image - Zoon In function
const zoomOut = (e) => {
	
	if(limit > 3){
		return true;
	}
	
	zoomValue = zoomValue + 0.02;
	limit = limit +1;
	
	
	
	//let left = $('.image-dragable').css("left");
	
	document.querySelector('.builder-svg').setAttribute("style","transform:scale("+zoomValue+")");
	document.querySelector('.image-dragable').setAttribute("style","transform:scale("+zoomValue+")");
	
	
	document.querySelector('.text-dragable').setAttribute("style","transform:scale("+zoomValue+")");
	
	/*const builder = document.querySelector('.builder-svg');
	let w = builder.getBoundingClientRect().width;	
	w = w + ((w * 5) / 100);
	document.querySelector('.builder-svg').setAttribute("style","width:"+w+"px");*/	
};


//Builder Image - Zoon Out function
const zoomIn= (e) => {
	
	if(limit < -3){
		return true;
	}
	
	zoomValue = zoomValue - 0.02;
	limit = limit - 1;
	
	document.querySelector('.builder-svg').setAttribute("style","transform:scale("+zoomValue+")");
	document.querySelector('.image-dragable').setAttribute("style","transform:scale("+zoomValue+")");
	document.querySelector('.text-dragable').setAttribute("style","transform:scale("+zoomValue+")");
	
	
	/*const builder = document.querySelector('.builder-svg');
	let w = builder.getBoundingClientRect().width;
	w = w - ((w * 5) / 100);
	document.querySelector('.builder-svg').setAttribute("style","width:"+w+"px");
	*/	
};

//Builder Image - Sticky for mobile only
const isSticky = (e) => {
    const zoomContainerOuter = document.querySelector('#zoom-container-outer');
    const scrollTop = window.scrollY;
    
    //const [height, setHeight] = useState(0);
    
    if(window.innerWidth <= 667){
    	scrollTop >= 100 ? zoomContainerOuter.classList.add('is-sticky') : zoomContainerOuter.classList.remove('is-sticky');
    }else{
    	zoomContainerOuter.classList.remove('is-sticky');
    }   
};

//Builder Image - scroll enable and left-sidebar margin from top for sticky builder for mobile only
const isMobile = (e) => {
    const zoomContainerOuter = document.querySelector('#zoom-container-outer');
    const zoomContainer = document.querySelector('#zoom-container');
    
    //Zoom scroll for zoom container
    document.querySelector('#zoom-container').setAttribute("style","height:"+zoomContainer.getBoundingClientRect().height+"px;");;
    zoomContainer.classList.add('scroll');
    
    
    let height = zoomContainerOuter.getBoundingClientRect().height;
    
    if(window.innerWidth <= 667){
    	zoomContainerOuter.classList.add('is-mobile');
    	document.getElementById('left-sidebar').setAttribute("style","margin-top:"+height+"px");
    }
    else{
    	document.getElementById('left-sidebar').setAttribute("style","margin-top:0");
    	zoomContainerOuter.classList.remove('is-mobile');
    }
};


export {zoomOut,zoomIn,isSticky,isMobile}




