import React from "react";
//import { Link } from "react-router-dom"; 
//import { useBetween } from 'use-between';


const PUBLIC_URL = process.env.PUBLIC_URL;


function Header() {
	

	//const { username, setUsername, count } = useBetween(useShareableState);
	
	/*function handleChange = (e) => {
	    setUsername(e.target.value);
	  }*/
  
  return (
		  <>
		  <header>
          <nav className="darkgraybg  d-flex">
              <div className="container">
                  <div className="row">
                      <div className="col-sm-12 col-md-12"> 
                             <a href="https://www.pearsox.com/"><img src={PUBLIC_URL + '/assets/images/logo.png'} width="" height="100" alt="pearsox_logo" title="pearsox_logo" /></a>
                      </div>
                  </div>
              </div>
          </nav>    
      </header>
     
      
      <div className="container" style={{display:"none"}}>
	      <div className="row">
	          <div className="col-sm-12 col-md-12">
	              <nav aria-label="breadcrumb">
	                  <ol className="breadcrumb">
	                  <li className="breadcrumb-item"><a href="/">HOME</a></li>
	                  <li className="breadcrumb-item"><a href="/">STOCK SOCKS</a></li>
	                  <li className="breadcrumb-item"><a href="/">ACE</a></li>
	                  <li className="breadcrumb-item active" aria-current="page">SOCK BUILDER</li>
	                  </ol>
	              </nav>
	          </div>
	      </div>
	  </div>
	  </>
  );
}

export default Header;




            
            
            
